.wrkLoader,
.wrkLoader:before,
.wrkLoader:after {
  background: #36628c;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.wrkLoader {
  color: #36628c;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.wrkLoader:before,
.wrkLoader:after {
  position: absolute;
  top: 0;
  content: '';
}
.wrkLoader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.wrkLoader:after {
  left: 1.5em;
}

.wrkLoader2,
.wrkLoader2:before,
.wrkLoader2:after {
  background: #dae6f1;
  -webkit-animation: load2 1s infinite ease-in-out;
  animation: load2 1s infinite ease-in-out;
  width: 3px;
  height: 10px;
  top: 10px;
}
.wrkLoader2 {
  color: #6c9ac6;
  text-indent: -9999em;
  // margin: 88px auto;
  position: relative;
  font-size: 9px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.wrkLoader2:before,
.wrkLoader2:after {
  position: absolute;
  top: 0;
  content: '';
}
.wrkLoader2:before {
  left: -0.9em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.wrkLoader2:after {
  left: 0.9em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@-webkit-keyframes load2 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 14px;
  }
  40% {
    box-shadow: 0 -2em;
    height: 20px;
  }
}
@keyframes load2 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 14px;
  }
  40% {
    box-shadow: 0 -2em;
    height: 20px;
  }
}
