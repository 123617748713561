/*  Leaflet MAP */
.leaflet-container {
  height: 400px;
  width: 80%;
  margin: 0 auto;
}

.leaflet-tile-pane {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.leaflet-popup-content-wrapper {
  background:#2c3e50;
  color:#fff;
  font-size:12px;
  // line-height:24px;
  }
.leaflet-popup-content-wrapper a {
  color:rgba(255,255,255,0.5);
  }
.leaflet-popup-tip-container {
  display: none;
  width:30px;
  height:15px;
  }
.leaflet-popup-close-button {
  display: none;
}
.leaflet-popup-tip {
  display: none;
  border-left:15px solid transparent;
  border-right:15px solid transparent;
  border-top:15px solid #2c3e50;
  }
/*  Leaflet popup */
