@-webkit-keyframes a{
  0%{
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg)
  }
  to{
    -webkit-transform:rotate(1turn);
    transform:rotate(1turn)
  }
}
.mk-spinner-ring{
  height:50px;
  margin-top:-25px;
  border-radius:50%;
  border:3px solid #00bcd4;
  border-left-color:transparent;
  background:transparent;
  -webkit-animation:a 1s infinite linear;
  animation:a 1s infinite linear
}
.mk-spinner-fan,.mk-spinner-ring{
  content:"";
  display:inline-block;
  position:absolute;
  width:50px;
  top:50%;
  left:50%;
  margin-left:-25px;
  z-index:1
}
.mk-spinner-fan{
  height:0;
  margin-top:0;
  border:3px solid #cebb17;
  border-radius:50%;
  -webkit-animation:a .8s infinite linear;
  animation:a .8s infinite linear
}
@-webkit-keyframes b{
  0%{
    -webkit-transform:scale(.9);
    transform:scale(.9)
  }
  to{
    -webkit-transform:scale(1);
    transform:scale(1)
  }
}
@keyframes b{
  0%{
    -webkit-transform:scale(.9);
    transform:scale(.9)
  }
  to{
    -webkit-transform:scale(1);
    transform:scale(1)
  }
}
@-webkit-keyframes c{
  0%{
    -webkit-transform:scale(.9);
    transform:scale(.9)
  }
  to{
    -webkit-transform:scale(4);
    transform:scale(4)
  }
}
@keyframes c{
  0%{
    -webkit-transform:scale(.9);
    transform:scale(.9)
  }
  to{
    -webkit-transform:scale(4);
    transform:scale(4)
  }
}
.mk-spinner-ripple:after,.mk-spinner-ripple:before{
  content:"";
  display:inline-block;
  position:absolute;
  width:20px;
  height:20px;
  top:50%;
  margin-top:-10px;
  left:50%;
  margin-left:-10px;
  z-index:1;
  border-radius:50%
}
.mk-spinner-ripple:before{
  background:#00796b;
  -webkit-animation:b 1s infinite .5s;
  animation:b 1s infinite .5s
}
.mk-spinner-ripple:after{
  background:#1de9b6;
  z-index:0;
  -webkit-transform:scale(3);
  transform:scale(3);
  -webkit-animation:c 1s infinite .5s;
  animation:c 1s infinite .5s
}
.mk-spinner-pie{
  content:"";
  display:inline-block;
  position:absolute;
  width:50px;
  height:50px;
  top:50%;
  margin-top:-25px;
  left:50%;
  margin-left:-25px;
  z-index:1;
  background:transparent;
  border-radius:50%;
  border:25px solid;
  border-color:#ffca28 #eeff41 #00bcd4 #1de9b6;
  -webkit-animation:a 1s infinite linear;
  animation:a 1s infinite linear
}
.mk-spinner-doublecircle{
  background:transparent;
  -webkit-animation:a 2s linear infinite;
  animation:a 2s linear infinite
}
.mk-spinner-doublecircle,.mk-spinner-doublecircle:after{
  border:3px solid;
  border-radius:50%;
  content:"";
  display:inline-block;
  position:absolute;
  width:50px;
  height:50px;
  top:50%;
  margin-top:-25px;
  left:50%;
  margin-left:-25px;
  z-index:1
}
.mk-spinner-doublecircle{
  border-color:#1de9b6 rgba(0,0,0,.07)
}
.mk-spinner-doublecircle:after{
  border-color:transparent #eeff41;
  -webkit-animation:a 5s linear infinite;
  animation:a 5s linear infinite
}
@keyframes a{
  0%{
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg)
  }
  to{
    -webkit-transform:rotate(1turn);
    transform:rotate(1turn)
  }
}
.mk-spinner-weight{
  -webkit-animation:a 1.5s infinite ease-in;
  animation:a 1.5s infinite ease-in;
  content:"";
  display:inline-block;
  position:absolute;
  width:50px;
  height:2px;
  top:50%;
  margin-top:-1px;
  left:50%;
  margin-left:-25px;
  z-index:1;
  background:#1de9b6
}
.mk-spinner-weight:after,.mk-spinner-weight:before{
  content:"";
  position:absolute;
  top:50%;
  border:2px solid #1de9b6;
  border-radius:50%
}
.mk-spinner-weight:before{
  width:12px;
  height:12px;
  margin-top:-6px;
  right:100%
}
.mk-spinner-weight:after{
  width:16px;
  height:16px;
  margin-top:-8px;
  left:100%
}
@-webkit-keyframes d{
  0%,to{
    -webkit-transform:scale(0);
    transform:scale(0)
  }
  50%{
    -webkit-transform:scale(1);
    transform:scale(1)
  }
}
@keyframes d{
  0%,to{
    -webkit-transform:scale(0);
    transform:scale(0)
  }
  50%{
    -webkit-transform:scale(1);
    transform:scale(1)
  }
}
@-webkit-keyframes e{
  0%,to{
    -webkit-transform:scale(1);
    transform:scale(1)
  }
  50%{
    -webkit-transform:scale(0);
    transform:scale(0)
  }
}
@keyframes e{
  0%,to{
    -webkit-transform:scale(1);
    transform:scale(1)
  }
  50%{
    -webkit-transform:scale(0);
    transform:scale(0)
  }
}
.mk-spinner-bubbles,.mk-spinner-bubbles:after,.mk-spinner-bubbles:before{
  content:"";
  display:inline-block;
  position:absolute;
  width:20px;
  height:20px;
  top:50%;
  margin-top:-10px;
  left:50%;
  margin-left:-10px;
  z-index:1;
  border-radius:50%;
  background:inherit
}
.mk-spinner-bubbles:before{
  left:-100%;
  -webkit-animation:d 1.5s infinite;
  animation:d 1.5s infinite
}
.mk-spinner-bubbles{
  opacity:.7;
  background:#1de9b6
}
.mk-spinner-bubbles:after{
  opacity:.5;
  left:200%;
  -webkit-animation:e 1.5s infinite;
  animation:e 1.5s infinite
}
@-webkit-keyframes f{
  0%{
    -webkit-transform:translateY(0);
    transform:translateY(0)
  }
  25%{
    -webkit-transform:translateY(-4px);
    transform:translateY(-4px)
  }
  50%{
    -webkit-transform:translateY(0);
    transform:translateY(0)
  }
  75%{
    -webkit-transform:translateY(4px);
    transform:translateY(4px)
  }
  to{
    -webkit-transform:translateY(0);
    transform:translateY(0)
  }
}
@keyframes f{
  0%{
    -webkit-transform:translateY(0);
    transform:translateY(0)
  }
  25%{
    -webkit-transform:translateY(-4px);
    transform:translateY(-4px)
  }
  50%{
    -webkit-transform:translateY(0);
    transform:translateY(0)
  }
  75%{
    -webkit-transform:translateY(4px);
    transform:translateY(4px)
  }
  to{
    -webkit-transform:translateY(0);
    transform:translateY(0)
  }
}
.mk-spinner-dots,.mk-spinner-dots:after,.mk-spinner-dots:before{
  content:"";
  display:inline-block;
  position:absolute;
  width:12px;
  height:12px;
  top:50%;
  margin-top:-6px;
  left:50%;
  margin-left:-6px;
  z-index:1;
  border-radius:50%;
  background:inherit;
  -webkit-transform-origin:center;
  transform-origin:center;
  -webkit-animation-name:f;
  animation-name:f;
  -webkit-animation-duration:2s;
  animation-duration:2s;
  -webkit-animation-iteration-count:infinite;
  animation-iteration-count:infinite;
  -webkit-animation-timing-function:ease-in-out;
  animation-timing-function:ease-in-out;
  -webkit-animation-fill-mode:both;
  animation-fill-mode:both
}
.mk-spinner-dots:before{
  left:-100%;
  -webkit-animation-delay:.5s;
  animation-delay:.5s;
  background:#1de9b6
}
.mk-spinner-dots{
  background:rgba(29,233,182,.6)
}
.mk-spinner-dots:after{
  left:200%;
  -webkit-animation-delay:1s;
  animation-delay:1s;
  background:rgba(29,233,182,.3)
}
@-webkit-keyframes g{
  0%,50%,60%{
    -webkit-transform:translateX(0);
    transform:translateX(0)
  }
  90%,to{
    -webkit-transform:translateX(-100%);
    transform:translateX(-100%)
  }
}
@keyframes g{
  0%,50%,60%{
    -webkit-transform:translateX(0);
    transform:translateX(0)
  }
  90%,to{
    -webkit-transform:translateX(-100%);
    transform:translateX(-100%)
  }
}
@-webkit-keyframes h{
  0%{
    -webkit-transform:translateX(0);
    transform:translateX(0)
  }
  50%,60%{
    -webkit-transform:translateX(-100%);
    transform:translateX(-100%)
  }
  90%,to{
    -webkit-transform:translateX(-200%);
    transform:translateX(-200%)
  }
}
@keyframes h{
  0%{
    -webkit-transform:translateX(0);
    transform:translateX(0)
  }
  50%,60%{
    -webkit-transform:translateX(-100%);
    transform:translateX(-100%)
  }
  90%,to{
    -webkit-transform:translateX(-200%);
    transform:translateX(-200%)
  }
}
.mk-spinner-boxes,.mk-spinner-boxes:after,.mk-spinner-boxes:before{
  content:"";
  display:inline-block;
  position:absolute;
  width:20px;
  height:20px;
  top:50%;
  margin-top:-10px;
  left:50%;
  margin-left:-10px;
  z-index:1
}
.mk-spinner-boxes{
  background:#1de9b6
}
.mk-spinner-boxes:after,.mk-spinner-boxes:before{
  margin-left:0;
  background:inherit
}
.mk-spinner-boxes:before{
  opacity:.5;
  left:100%;
  -webkit-animation:g 2s infinite 2s;
  animation:g 2s infinite 2s
}
.mk-spinner-boxes:after{
  opacity:.2;
  left:200%;
  -webkit-animation:h 2s infinite;
  animation:h 2s infinite
}
*,:after,:before{
  -webkit-box-sizing:border-box;
  box-sizing:border-box
}
.mk-spinner-wrap{
  min-height:100px;
  width:100%;
  position:relative
}
@-webkit-keyframes i{
  0%,5%{
    left:0;
    top:0
  }
  50%,55%{
    left:50%;
    top:50%
  }
  95%,to{
    left:0;
    top:0
  }
}
@keyframes i{
  0%,5%{
    left:0;
    top:0
  }
  50%,55%{
    left:50%;
    top:50%
  }
  95%,to{
    left:0;
    top:0
  }
}
@-webkit-keyframes j{
  0%,5%{
    right:0;
    bottom:0
  }
  50%,55%{
    right:50%;
    bottom:50%
  }
  95%,to{
    right:0;
    bottom:0
  }
}
@keyframes j{
  0%,5%{
    right:0;
    bottom:0
  }
  50%,55%{
    right:50%;
    bottom:50%
  }
  95%,to{
    right:0;
    bottom:0
  }
}
.mk-spinner-window{
  content:"";
  display:inline-block;
  position:absolute;
  width:36px;
  height:36px;
  top:50%;
  margin-top:-18px;
  left:50%;
  margin-left:-18px;
  z-index:1;
  background:#82b3ae
}
.mk-spinner-window:after,.mk-spinner-window:before{
  content:"";
  position:absolute;
  width:18px;
  height:18px;
  background:#6fa7a2
}
.mk-spinner-window:before{
  top:0;
  left:0;
  background:#6fa7a2;
  background:rgba(0,0,0,.1);
  -webkit-animation:i 3s infinite linear;
  animation:i 3s infinite linear
}
.mk-spinner-window:after{
  right:0;
  bottom:0;
  background:#62a099;
  background:rgba(0,0,0,.2);
  -webkit-animation:j 3s infinite linear;
  animation:j 3s infinite linear
}
