/* Amplify login */
[data-amplify-authenticator] {
  width: 100%;
  background-image: url('../../assets/images/background/background.jpg');
  background-size: cover;
}

[class^='Form__formSection___'] {
  opacity: 0.9;
}

.amplify-tabs {
  display: none !important;
}
/*----------------------*/

.mini-card {
  margin-top: -60px;
  margin-left: 30px;
  width: fit-content;
}

.side-nav {
  background-color: #f9f9f9 !important;
}

.highlight-sidebar {
  background-color: #3f51e5 !important;
  color: white !important;
}

.tablecell-sheet-name {
  width: 20%;
  word-break: break-all;
  font-size: small !important;
}

.w100 {
  width: 100%;
}

.tolowercase {
  text-transform: lowercase;
}

.header-menu-icon {
  color: white;
}

.list-ball {
  padding: 12px;
  border-radius: 26px;
  background-color: #1c5ea0;
  /*background-image: url('../../assets/images/list_background.png');*/
  background-size: contain;
  width: 160px;
  height: 60px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: larger;

  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
}

.ball-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 24px;
}

.list-subheader-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  .spacer {
    flex-grow: 3;
  }

  * {
    margin-right: 40px !important;
  }
}

.letter-ball {
  font-family: 'Varela Round', sans-serif;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3f51b5;
  color: white;
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.title-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 40pt;
}

.color-card {
  padding: 10px;
  border-radius: 10px;
  background-color: #a1cbe0;
  color: black;
  font-size: 9pt;
  display: flex;
  flex-direction: row;
}

.pageOverlay {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5555;
  display: flex;
  background-color: #333333;
  opacity: 0.3;
  align-items: center;
  justify-content: center;
}

.alert-dialog {
  width: 550px;
}

.construction-container {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/background/construction.jpg');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .under-construction {
    width: 512px;
    height: 288px;
    background-image: url('../../assets/images/under_construction.png');
    background-size: auto;
    transform: rotate(-10deg);
  }
}

.restricted-access {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  background-color: white;
  position: relative;
  background-size: contain;

  .restricted-image {
    width: 100px;
    height: 100px;
    opacity: 0.3;
    background-image: url('../../assets/images/restricted.png');
    background-size: contain;
  }
}

.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  background-color: white;
  position: relative;

  .welcome-image {
    width: 320px;
    height: 320px;
    background-image: url('../../assets/images/welcome.jpg');
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.list-subheader {
  padding-top: 12px;
  font-weight: bold !important;
}

.list-row:hover {
  background-color: #efefef;
}

.logViewCard {
  overflow: auto !important;
  height: 420px;
  white-space: pre;
}

.bigSelectionBox {
  height: 100%;
  width: 100%;

  select {
    height: 95% !important;
  }
}
.bigSelectionBox::before {
  border-bottom: none !important;
}
.bigSelectionBox::after {
  border-bottom: none !important;
}

.side-menu {
  height: 100%;
  padding-left: 20px;
  padding-top: 20px;

  .nav-header {
    font-weight: bold;
    color: darkgreen;
    background-color: beige;
  }

  .nav-collapse-btn {
    padding-left: 0px !important;
  }

  .nav-collapse-item {
    padding-left: 16px;
  }
}
